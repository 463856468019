
import gql from 'graphql-tag';

// TODO: confirm required response
const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    loginAsAdmin(email: $email, password: $password) {
      username,
      email,
      roles
    }
  }
`;

// TODO: confirm required response
const REGISTER = gql`
  mutation Register($email: String!, $password: String!) {
    register(email: $email, password: $password) {
      id
      email
    }
  }
`;

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser($email: String!) {
    deleteUser(email: $email) {
      _id
    }
  }
`;

const CREATE_USER = gql`
  mutation CreateUser($user: UserInput!) {
    createUser(user: $user) {
      _id
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($user: UserInput!) {
    updateUser(user: $user) {
      _id
    }
  }
`;


export {
  LOGIN,
  REGISTER,
  DELETE_USER,
  CREATE_USER,
  UPDATE_USER,
  LOGOUT
};
