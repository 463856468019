
import { ApolloClient } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import { BACKEND_GRAPHQL_URL } from 'config';
import typeDefs from 'schemas';
import resolvers from 'resolvers';
import { setInitialCacheData } from 'utils/helpers/cache';

const cache = new InMemoryCache({
  dataIdFromObject: ({ _id, id }) => {
    if (id && _id) return id + _id;
    if (_id) return _id;
    if (id) return id;
  }
});

const client = new ApolloClient({
  link: new HttpLink({
    uri: BACKEND_GRAPHQL_URL,
    credentials: 'include'
  }),
  cache,
  typeDefs,
  resolvers
});

setInitialCacheData(cache);

export default client;
