/* eslint-disable max-len */

import gql from 'graphql-tag';

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn
  }
`;

const USERS = gql`
  query Users($roles: [UserRoleEnums!]!, $offset: Int!,  $limit: Int!, $field: String, $value: String) {
    users(roles: $roles, offset: $offset, limit: $limit, field: $field, value: $value) {
      users {
        _id
        username
        email
        roles
        enabled
        firstName
        lastName
        balance
        countryCode
        phoneNumber
        phoneVerified
        createdAt
        updatedAt
      }
      pageInfo {
        total
        hasNext
        limit
        currentPage
      }
    }
  }
`;

const USERSSEARCH = gql`
  query Users($roles: [UserRoleEnums!]!, $offset: Int!,  $limit: Int!) {
    users(roles: $roles, offset: $offset, limit: $limit) {
      users {
        _id
        username
        email
        roles
        enabled
        firstName
        lastName
        balance
        countryCode
        phoneNumber
        phoneVerified
        createdAt
        updatedAt
      }
      pageInfo {
        total
        hasNext
        limit
        currentPage
      }
    }
  }
`;

const USER = gql`
  query User($_id: ID!) {
    user (_id: $_id) {
      _id
      username
      email
      firstName
      lastName
      roles
      balance
      countryCode
      phoneNumber
      enabled
    }
  }
`;
const ADMINUSERS = gql`
query Users($roles: [UserRoleEnums!]!, $offset: Int!,  $limit: Int!) {
  users(roles: $roles, offset: $offset, limit: $limit) {
    users {
      _id
      username
      email
      firstName
      lastName
      roles
      enabled
    }
  }
  }
`;

export {
  IS_LOGGED_IN,
  USERS,
  USER,
  USERSSEARCH,
  ADMINUSERS
};
