
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: theme.custom.layout.drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: theme.custom.layout.drawerWidth,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    borderRight: `1px solid ${theme.custom.palette.lightGrey}`
  }
}));

const DrawerWrapper = ({ children, open, closeDrawer }) => {
  const classes = useStyles();

  return (
    <>
      {/* TODO: Drawer was duplicated */}
      <Hidden
        lgUp
        implementation='css'>
        <Drawer
          variant='temporary'
          open={open}
          onClose={closeDrawer}
          classes={{
            root: classes.drawer,
            paper: classes.drawerPaper
          }}>
          {children}
        </Drawer>
      </Hidden>
      <Hidden
        mdDown
        implementation='css'>
        <Drawer
          variant='permanent'
          open={open}
          onClose={closeDrawer}
          classes={{
            root: classes.drawer,
            paper: classes.drawerPaper
          }}>
          {children}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DrawerWrapper;
