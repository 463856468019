
// TODO: should ask advice about the coloring
const SCROLLBAR_BACKGROUND_COLOR = '#cfd8dc';
const THUMB_BACKGROUND_COLOR = '#90a4ae';

const ICAST_SCROLLBAR_CLASS_NAME = 'icast-scrollbar';

export {
  SCROLLBAR_BACKGROUND_COLOR,
  THUMB_BACKGROUND_COLOR,
  ICAST_SCROLLBAR_CLASS_NAME
};
