
import React, { Suspense, lazy, useState } from 'react';
import 'typeface-roboto';
import { Switch, Route, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/react-hooks';
import pMinDelay from 'p-min-delay';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import client from 'lib/apollo';
import Layout from 'hoc/Layout';
import Loading from 'components/Loading';
import theme from 'styles/theme';
import PAGES from 'utils/links/pages';
import { AppContext } from 'contexts';
import { useAuth } from 'utils/hooks';
import QUERY_PARAMS from 'utils/constants/query-params';

const SignIn = lazy(() => pMinDelay(import(/* webpackChunkName: "sign-in" */ 'pages/Auth/SignIn'), 200));
const Dashboard = lazy(() => pMinDelay(import(/* webpackChunkName: "dashboard" */ 'pages/Dashboard'), 200));

const UserManagement = lazy(() => pMinDelay(import(/* webpackChunkName: "user-management" */ 'pages/UserManagement'), 200));
const AddOrEditUser = lazy(
  () => pMinDelay(import(/* webpackChunkName: "add-or-edit-user" */ 'pages/UserManagement/AddOrEditUser'), 200)
);

const PoolManagement = lazy(() => pMinDelay(import(/* webpackChunkName: "pool-management" */ 'pages/PoolManagement'), 200));
const PoolGroup = lazy(() => pMinDelay(import(/* webpackChunkName: "pool-group" */ 'pages/PoolManagement/PoolGroup'), 200));

const CastManagement = lazy(() => pMinDelay(import(/* webpackChunkName: "cast-management" */ 'pages/CastManagement'), 200));
const PoolCast = lazy(() => pMinDelay(import(/* webpackChunkName: "pool-cast" */ 'pages/CastManagement/PoolCast'), 200));
const CastDetail = lazy(() => pMinDelay(import(/* webpackChunkName: "pool-cast" */ 'pages/CastManagement/CastDetail'), 200));

const FixtureManagement = lazy(
  () => pMinDelay(import(/* webpackChunkName: "fixture-management" */ 'pages/FixtureManagement'), 200)
);

const BannerManagement = lazy(
  () => pMinDelay(import(/* webpackChunkName: "fixture-management" */ 'pages/BannerManagement'), 200)
);
const AddOrEditBanner = lazy(
  () => pMinDelay(import(/* webpackChunkName: "add-or-edit-user" */ 'pages/BannerManagement/AddOrEditBanner'), 200)
);

const NoMatch = lazy(() => pMinDelay(import(/* webpackChunkName: "no-match" */ 'pages/NoMatch'), 200));

const useStyles = makeStyles(() => ({
  primaryTextColor: {
    color: '#fff'
  }
}));

const App = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const openDrawerHandler = () => {
    if (!drawerOpen) {
      setDrawerOpen(true);
    }
  };

  const closeDrawerHandler = () => {
    if (drawerOpen) {
      setDrawerOpen(false);
    }
  };

  const Routers = () => {
    const { loadingIsLoggedIn, isLoggedIn } = useAuth();
    if (loadingIsLoggedIn) return <Loading wholeOverlay />;

    return (
      isLoggedIn === true ? (
        <Layout>
          <Suspense fallback={<Loading />}>
            <Switch>
              {/* dashboard */}
              <Route
                exact
                path={PAGES.DASHBOARD}
                component={Dashboard} />

              {/* user management */}
              <Route
                exact
                path={PAGES.USER_MANAGEMENT}
                component={UserManagement} />
              {/**
               * TODO:
               * Should be query rather than params
               * inspired by https://medium.com/better-programming/restful-api-design-step-by-step-guide-2f2c9f9fcdbf.
               */}
              <Route
                exact
                path={`${PAGES.ADD_USER}/:${QUERY_PARAMS.USER_TYPE_ID}`}
                component={AddOrEditUser} />
              <Route
                exact
                path={`${PAGES.EDIT_USER}/:${QUERY_PARAMS.USER_TYPE_ID}/:${QUERY_PARAMS.USER_ID}`}
                component={AddOrEditUser} />

              {/* pool management */}
              <Route
                exact
                path={PAGES.POOL_MANAGEMENT}
                component={PoolManagement} />
              {/**
               * TODO:
               * Should be query rather than params
               * inspired by https://medium.com/better-programming/restful-api-design-step-by-step-guide-2f2c9f9fcdbf.
               */}
              <Route
                exact
                path={`${PAGES.ADD_POOL_GROUP}/:${QUERY_PARAMS.SPORTS_TYPE}`}
                component={PoolGroup} />
              <Route
                exact
                path={`${PAGES.EDIT_POOL_GROUP}/:${QUERY_PARAMS.SPORTS_TYPE}/:${QUERY_PARAMS.POOL_GROUP_ID}`}
                component={PoolGroup} />

              {/* cast management */}
              <Route
                exact
                path={PAGES.CAST_MANAGEMENT}
                component={CastManagement} />
              {/**
               * TODO:
               * Should use query parameters rather than URL parameters
               * inspired by https://medium.com/better-programming/restful-api-design-step-by-step-guide-2f2c9f9fcdbf.
               */}
              <Route
                exact
                path={`${PAGES.POOL_CAST}/:${QUERY_PARAMS.SPORTS_TYPE}/:${QUERY_PARAMS.ID}/:${QUERY_PARAMS.TITLE}/:${QUERY_PARAMS.STATUS}`}
                component={PoolCast} />
              <Route
                exact
                path={`${PAGES.CAST_DETAIL}/:${QUERY_PARAMS.POOL_ID}/:${QUERY_PARAMS.ID}`}
                component={CastDetail} />
              <Route
                exact
                path={`${PAGES.MATCH_RESULT}/:${QUERY_PARAMS.POOL_ID}`}
                component={CastDetail} />

              {/* match management */}
              <Route
                exact
                path={PAGES.FIXTURE_MANAGEMENT}
                component={FixtureManagement} />
              <Route
                exact
                path={PAGES.BANNER_MANAGEMENT}
                component={BannerManagement} />
              <Route
                exact
                path={PAGES.ADD_BANNER}
                component={AddOrEditBanner} />
              <Route
                exact
                path={`${PAGES.EDIT_BANNER}/:${QUERY_PARAMS.ID}`}
                component={AddOrEditBanner} />
              {/* <Route
                exact
                path={PAGES.ADD_BANNER}
                component={BannerManagement} /> */}

              <Route path='*'>
                <NoMatch />
              </Route>
            </Switch>
          </Suspense>
        </Layout>
      ) : (
        <Redirect to={{ pathname: PAGES.SIGN_IN }} />
      )
    );
  };

  return (
    <AppContext.Provider
      value={{
        drawerOpen,
        openDrawerHandler,
        closeDrawerHandler
      }}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            classes={{
              variantSuccess: classes.primaryTextColor,
              variantError: classes.primaryTextColor,
              variantWarning: classes.primaryTextColor,
              variantInfo: classes.primaryTextColor
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            maxSnack={3}>
            <CssBaseline />
            <Suspense fallback={<Loading wholeOverlay />}>
              <Switch>
                <Route
                  exact
                  path={PAGES.SIGN_IN}
                  component={SignIn} />
                <Route render={() => <Routers />} />
              </Switch>
            </Suspense>
          </SnackbarProvider>
        </ThemeProvider>
      </ApolloProvider>
    </AppContext.Provider>
  );
};

export default App;
