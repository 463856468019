
import { POOL_GROUPS } from 'api/poolGroup/queries';
import SPORTS_TYPES from 'utils/constants/sports-types';

const resolvers = {
  Mutation: {
    createOrUpdateCachePoolGroup: (_root, { poolGroup }, { cache }) => {
      // TODO: how can we know it's SOCCER?
      // TODO: Or _id is enough?
      try {
        const {
          poolGroups: existingPoolGroups
        } = cache.readQuery({
          query: POOL_GROUPS,
          variables: { sportsType: SPORTS_TYPES[0].value }
        });
        if (!existingPoolGroups) {
          return;
        }
        let newPoolGroups = existingPoolGroups.filter(existingPoolGroup => existingPoolGroup._id !== poolGroup._id);
        newPoolGroups = [...newPoolGroups, poolGroup];
        cache.writeQuery({
          query: POOL_GROUPS,
          variables: {
            // TODO: how can we know it's SOCCER?
            // TODO: Or _id is enough?
            sportsType: SPORTS_TYPES[0].value
          },
          data: { poolGroups: newPoolGroups }
        });
      } catch (error) {
        console.log('[resolvers poolGroup createOrUpdateCachePoolGroup] error => ', error);
      }
    },
    deleteCachePoolGroup: (_root, { poolGroupId, sportsType }, { cache }) => {
      const { poolGroups } = cache.readQuery({ query: POOL_GROUPS, variables: { sportsType } });
      const newPoolGroups = poolGroups.filter(poolGroup => poolGroup._id !== poolGroupId);
      cache.writeQuery({
        query: POOL_GROUPS,
        variables: {
          sportsType
        },
        data: { poolGroups: newPoolGroups }
      });
    }
  }
};

export default resolvers;
