
const PAGES = {
  HOME: '/',
  SIGN_IN: '/auth/sign-in',
  FORGOT_PASSWORD: '/auth/sign-in', // TODO: pending '/auth/forgot-password'
  TERMS_OF_SERVICE: '/terms-of-service',
  DASHBOARD: '/dashboard',
  USER_MANAGEMENT: '/user-management',
  ADD_USER: '/user-management/add-user',
  EDIT_USER: '/user-management/edit-user',
  POOL_MANAGEMENT: '/pool-management',
  ADD_POOL_GROUP: '/pool-management/add-pool-group',
  EDIT_POOL_GROUP: '/pool-management/edit-pool-group',
  CAST_MANAGEMENT: '/cast-management',
  FIXTURE_MANAGEMENT: '/fixture-management',
  POOL_CAST: '/cast-management/pool',
  CAST_DETAIL: '/cast-management/cast-detail',
  MATCH_RESULT: '/cast-management/match-result',
  BANNER_MANAGEMENT: '/banner-management',
  ADD_BANNER: '/banner-management/add-banner',
  EDIT_BANNER: '/banner-management/edit-banner'
};

export default PAGES;
