
import React, { useContext } from 'react';
import Toolbar from '@material-ui/core/Toolbar';

import DrawerWrapper from 'hoc/DrawerWrapper';
import LogoWithTitle from 'components/LogoWithTitle';
import SideMenuList from './SideMenuList';
import { AppContext } from 'contexts';
import SIDE_MENU_ITEMS from 'utils/links/side-menu-items';

const SideDrawer = () => {
  const { drawerOpen, closeDrawerHandler } = useContext(AppContext);

  return (
    <DrawerWrapper
      open={drawerOpen}
      closeDrawer={closeDrawerHandler}>
      <Toolbar>
        <LogoWithTitle
          logoWidth={28}
          logoHeight={23.8}
          titleVariant='h6' />
      </Toolbar>
      <SideMenuList menuItems={SIDE_MENU_ITEMS} />
    </DrawerWrapper>
  );
};

export default SideDrawer;
