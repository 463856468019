
import { useState, useEffect } from 'react';

const useDelayedRender = (delay = 0, fallback) => {
  const [delayed, setDelayed] = useState(true);
  useEffect(() => {
    if (delay > 0) {
      const timeout = setTimeout(() => setDelayed(false), delay);

      return () => clearTimeout(timeout);
    }
  }, [delay]);

  return fn => (!delay || !delayed) ? fn() : fallback || null;
};

export default useDelayedRender;
