
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  picture: {
    display: 'flex'
  },
  img: props => ({
    width: props.width,
    height: props.height
  })
}));

const Logo = ({ width, height, className, ...rest }) => {
  const classes = useStyles({ width, height });

  return (
    <picture
      className={clsx(classes.picture, className)}
      {...rest}>
      <source
        srcSet={`
          /assets/images/logo/webp/blue.webp 600w,
          /assets/images/logo/webp/blue@2x.webp 960w,
          /assets/images/logo/webp/blue@3x.webp 1280w
        `}
        type='image/webp' />
      <source
        srcSet={`
          /assets/images/logo/png/blue.png 600w,
          /assets/images/logo/png/blue@2x.png 960w,
          /assets/images/logo/png/blue@3x.png 1280w
        `}
        type='image/png' />
      <img
        className={classes.img}
        width='100%'
        src='/assets/images/placeholder.gif'
        alt='icast' />
    </picture>
  );
};

export default Logo;
