
import objectKeyFilter from 'object-key-filter';

const removeTypeName = data => {
  return typeof data === 'object' ? objectKeyFilter(data, ['__typename'], true) : data;
};

const addOrdinalSuffix = number => {
  const remainderOfTenDivision = number % 10;
  const remainderOfHundredDivision = number % 100;
  if (remainderOfTenDivision === 1 && remainderOfHundredDivision !== 11) {
    return number + 'st';
  }
  if (remainderOfTenDivision === 2 && remainderOfHundredDivision !== 12) {
    return number + 'nd';
  }
  if (remainderOfTenDivision === 3 && remainderOfHundredDivision !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

const generateSlices = ({ totalCount, lastValue, totalValue }) => {
  const resultValues = [];
  const firstValue = 2 * totalValue / totalCount - lastValue;
  const ratio = (firstValue - lastValue) / (totalCount - 1);
  resultValues[totalCount -1] = Number(lastValue);
  for (let i = totalCount - 2; i >= 0; i--) {
    resultValues[i] = Math.floor((Number(resultValues[i+1]) + Number(ratio)) * 100) / 100;
  }
  return resultValues;
};

const checkValidNumber =
  number => (typeof(number) === 'number' || (typeof(number) === 'string' && number.trim() !== '')) && !isNaN(number);

const toUpperCaseOfFirstChar = text => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
};

const convertToNumber = value => {
  return Number(value);
};

export {
  removeTypeName,
  addOrdinalSuffix,
  generateSlices,
  checkValidNumber,
  toUpperCaseOfFirstChar,
  convertToNumber
};
