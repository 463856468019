
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'react-lottie';

import DelayedRenderWrapper from 'hoc/DelayedRenderWrapper';
import loadingSpinningBasketball from 'assets/lotties/loading-spinning-basketball.json';

const loadingDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingSpinningBasketball,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const useStyles = makeStyles(theme => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      margin: '0 !important'
    }
  },
  backdrop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    zIndex: theme.zIndex.drawer + 10
  }
}));

const Loading = ({ wholeOverlay }) => {
  const classes = useStyles();

  return (
    <DelayedRenderWrapper delay={500}>
      <div className={wholeOverlay ? classes.backdrop : classes.loading}>
        <Lottie
          className={classes.noMargin}
          height={100}
          width={100}
          options={loadingDefaultOptions} />
      </div>
    </DelayedRenderWrapper>
  );
};

export default Loading;
