/* eslint-disable no-const-assign */

import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';

import { IS_LOGGED_IN } from 'api/user/queries';
import { LOGIN, LOGOUT } from 'api/user/mutations';
import PAGES from 'utils/links/pages';
import { setInitialCacheData } from 'utils/helpers/cache';
import { convertToNumber } from 'utils/helpers/common';
import { uploadFile } from 'react-s3';
require('dotenv').config();

/**
 * TODO:
 * Could be better to show error messages via notifications
 * as in the frontend instead of showing them on a different view via Error component.
 * Should use type="tel" for phone numbers.
 */
const config = {
  bucketName: process.env.REACT_APP_AWS_CONFIG,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_accessKeyId,
  secretAccessKey: process.env.REACT_APP_secretAccessKey
};
const useModal = modalName => {
  const [modalOpen, setModalOpen] = useState({ [modalName]: false });

  // TODO: modalName could be omitted
  const openModalHandler = modalName => () => {
    setModalOpen({ [modalName]: true });
  };

  // TODO: modalName could be omitted
  const closeModalHandler = modalName => () => {
    setModalOpen({ [modalName]: false });
  };

  return {
    modalOpen,
    openModalHandler,
    closeModalHandler
  };
};

const useForm = ({ submitCallback, initialInputs = {} } = {}) => {
  const [inputs, setInputs] = useState(initialInputs);
  const onSubmitHandler = event => {
    event && event.preventDefault();
    submitCallback && submitCallback();
  };

  const inputChangeHandler = useCallback(event => {
    event.persist && event.persist();
    // if (event.target.type === 'file') {
    //   const files = event.target.files;
    //   const reader = new FileReader();
    //   reader.readAsDataURL(files[0]);
    //   let fileData = '';
    //   reader.onload = e => {
    //     // this.setState({
    //     // });
    //     fileData = e.target.result;
    //     setInputs(inputs => ({
    //       ...inputs,
    //       [event.target.name]:
    //       fileData
    //     }));
    //   };
    //   console.log(fileData, 'e.target.resulte.target.result');
    // } else {
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.type === 'checkbox' ?
        event.target.checked :
        event.target.type === 'number' ?
          convertToNumber(event.target.value) :
          event.target.value
    }));
    // }
    if (event.target.type === 'file' && event.target.name === 'banner_file') {
      const file = event.target.files[0];
      if (file) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width !== 1920 || img.height !== 1090) {
            setInputs(inputs => ({
              ...inputs,
              file_error: '** Image dimensions must be 1920px x 1090px'
            }));
            return;
          }
          try {
            uploadFile(file, config)
              .then(data => {
                setInputs(inputs => ({
                  ...inputs,
                  file_data:
                    data.location,
                  file_error: ''

                }));
              })
              .catch(err => console.error(err));
          } catch (error) {
            console.log('File upload failed. Please try again.', error);
          }
        };
      } else {
        console.error('No file selected');
        return 'No file selected';
      }


      // const base64 = files.toString('base64');
      // console.log(base64.substr(0, 200));
      // const burger = new Buffer(base64, 'base64');
      // setInputs(inputs => ({
      //   ...inputs,
      //   burger:
      //   burger
      // }));
    }
  }, []);

  const updateInputsHandler = useCallback(newInputs => {
    setInputs(newInputs);
  }, []);

  return {
    onSubmitHandler,
    inputChangeHandler,
    inputs,
    updateInputsHandler
  };
};

const useAuth = () => {
  const history = useHistory();
  const cache = useApolloClient();
  const { loading: loadingIsLoggedIn, data: { isLoggedIn } = {} } = useQuery(IS_LOGGED_IN);

  const [login, { loading: loadingLogin, error: errorLogin }] = useMutation(LOGIN, {
    onCompleted: () => {
      cache.writeData({ data: { isLoggedIn: true } });
      history.push(PAGES.POOL_MANAGEMENT);
    },
    onError: ({ graphQLErrors }) => {
      console.log('[utils hooks useAuth onError] graphQLErrors => ', graphQLErrors);
    }
  });
  const [logout] = useMutation(LOGOUT);

  const logOutHandler = () => {
    logout({
      async update() {
        history.push(PAGES.SIGN_IN);
        await cache.resetStore();
        await setInitialCacheData(cache);
      }
    });
  };

  const logInHandler = ({ variables }) => {
    login({ variables });
  };

  return {
    loadingIsLoggedIn,
    loadingLogin,
    errorLogin,
    isLoggedIn,
    logInHandler,
    logOutHandler
  };
};

const useTab = () => {
  const [selectedTabIndex, setSelectedIndex] = useState(0);

  const onTabChangeHandler = (event, newTabIndex) => {
    setSelectedIndex(newTabIndex);
  };

  return {
    selectedTabIndex,
    onTabChangeHandler
  };
};

export {
  useModal,
  useAuth,
  useForm,
  useTab
};
