
const SPORTS_TYPES = [
  {
    id: 'soccer',
    label: 'Soccer',
    value: 'SOCCER'
  },
  {
    id: 'horse-racing',
    label: 'Horse Racing',
    value: 'HORSE_RACING'
  },
  {
    id: 'cricket',
    label: 'Cricket',
    value: 'CRICKET'
  }
];

export default SPORTS_TYPES;
