
import React, { useContext } from 'react';
import List from '@material-ui/core/List';
import { useHistory } from 'react-router-dom';

import SideMenuListItem from './SideMenuListItem';
import { AppContext } from 'contexts';
import SIDE_MENU_ITEMS from 'utils/links/side-menu-items';

const SideMenuList = ({ menuItems }) => {
  const { closeDrawerHandler } = useContext(AppContext);
  const history = useHistory();

  const sideMenuIndex = React.useMemo(() => {
    const routePathname = history.location.pathname.split('/').filter(x => x !== '')[0];
    const sideMenuIndex = SIDE_MENU_ITEMS.findIndex(sideMenuItem => sideMenuItem.link === `/${routePathname}`);
    return sideMenuIndex > 0 ? sideMenuIndex : 0;
  }, [history.location.pathname]);

  const listItemClickHandler = index => event => {
    closeDrawerHandler();
    history.push(menuItems[index].link);
  };

  return (
    <List>
      {menuItems.map((menuItem, index) => {
        const selected = sideMenuIndex === index;
        return (
          <SideMenuListItem
            key={menuItem.id}
            selected={selected}
            menuItem={menuItem}
            onClick={listItemClickHandler(index)} />
        );
      })}
    </List>
  );
};

export default SideMenuList;
