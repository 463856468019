
import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import CasinoIcon from '@material-ui/icons/Casino';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import AddAPhoto from '@material-ui/icons/AddAPhoto';

import PAGES from 'utils/links/pages';

const SIDE_MENU_ITEMS = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    icon: <DashboardIcon />,
    link: PAGES.DASHBOARD
  },
  {
    id: 'user-management',
    name: 'User Manangement',
    icon: <PersonIcon />,
    link: PAGES.USER_MANAGEMENT
  },
  {
    id: 'pool-management',
    name: 'Pool Management',
    icon: <PermDataSettingIcon />,
    link: PAGES.POOL_MANAGEMENT
  },
  {
    id: 'cast-management',
    name: 'Cast Management',
    icon: <CasinoIcon />,
    link: PAGES.CAST_MANAGEMENT
  },
  {
    id: 'fixture-management',
    name: 'Fixture Management',
    icon: <SportsSoccerIcon />,
    link: PAGES.FIXTURE_MANAGEMENT
  },
  {
    id: 'banner-management',
    name: 'Banner Management',
    icon: <AddAPhoto />,
    link: PAGES.BANNER_MANAGEMENT
  }
];

export default SIDE_MENU_ITEMS;
