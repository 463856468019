
import { LEAGUES } from 'api/league/queries';

const resolvers = {
  Mutation: {
    updateCacheLeagueToSync: (_root, { leagueId, countryId }, { cache }) => {
      const { leagues } = cache.readQuery({ query: LEAGUES, variables: { country: countryId } });
      const newLeagues = leagues.map(league => {
        if (String(league._id) === String(leagueId)) {
          return { ...league, isSyncing: true };
        } else {
          return league;
        }
      });
      cache.writeQuery({
        query: LEAGUES,
        variables: {
          country: countryId
        },
        data: { leagues: newLeagues }
      });
    }
  }
};

export default resolvers;
