
import gql from 'graphql-tag';

const POOL_GROUP = gql`
  query PoolGroup($_id: String) {
    poolGroup(_id: $_id) {
      countrys_name
      _id
      name
      prefix
      sportsType {
        _id
        name
      }
      interval {
        _id
        value
      }
      startDate
      endDate
      maxFixtureCount
      enabled
      poolType {
        _id
        name
      }
      preferredTeams {
        _id
        id
        name
        country
      }
      casting {
        _id
        ... on SoccerCasting {
          specification {
            castingType
          }
        }
      }
      ... on SoccerPoolGroup {
        specification {
          isHomeFirst
          leagues {
            _id
            id
            name
            season
            country
          }
        }
      }
      pools {
        _id
        poolGroupId
        name
        startDate
        endDate
        status
        sportsType {
          _id
          name
        }
        fixtures {
          _id
        }
      }
    }
  }
`;

const POOL_GROUPS = gql`
  query poolGroups($sportsType: SportsTypeEnums!) {
    poolGroups(sportsType: $sportsType) {
      _id
      name
      countrys_name
      startDate
      endDate
      maxFixtureCount
      enabled
      poolType {
        _id
        name
      }
      pools {
        _id
      }
      casting {
        _id
        ... on SoccerCasting {
          specification {
            castingType
          }
        }
      }
      ... on SoccerPoolGroup {
        specification {
          leagues {
            _id
            id
            name
            sportsType {
              _id
              name
            }
            country 
          }
        }
      }
    }
  }
`;

export {
  POOL_GROUP,
  POOL_GROUPS
};
