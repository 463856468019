
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import {
  SCROLLBAR_BACKGROUND_COLOR,
  THUMB_BACKGROUND_COLOR,
  ICAST_SCROLLBAR_CLASS_NAME
} from 'styles/common';

const theme = responsiveFontSizes(createMuiTheme({
  palette: {
    primary: {
      light: '#4791db',
      main: '#1976d3',
      dark: '#115293',
      contrastText: '#fff'
    },
    secondary: {
      light: '#555e6c',
      main: '#2b3648',
      dark: '#1e2532',
      contrastText: '#fff'
    },
    error: {
      light: '#ff3333',
      main: '#ff0000',
      dark: '#b20000',
      contrastText: '#fff'
    },
    background: {
      default: '#212936'
    },
    text: {
      primary: '#fff',
      secondary: '#8697a2'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        [`body, *, .${ICAST_SCROLLBAR_CLASS_NAME}`]: {
          scrollbarWidth: 'thin',
          scrollbarColor: `${THUMB_BACKGROUND_COLOR} ${SCROLLBAR_BACKGROUND_COLOR}`,
          '&::-webkit-scrollbar': {
            width: 11
          },
          '&::-webkit-scrollbar-track': {
            background: SCROLLBAR_BACKGROUND_COLOR
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: THUMB_BACKGROUND_COLOR,
            borderRadius: 6,
            border: `3px solid ${SCROLLBAR_BACKGROUND_COLOR}`
          }
        }
      }
    }
  },
  custom: {
    palette: {
      blueGrey: '#5c739c',
      lightGrey: '#31373f',
      highlight: '#00ff00',
      disabled: {
        main: '#2b364880',
        contrastText: '#ffffff42'
      }
    },
    layout: {
      drawerWidth: 232,
      inputLabelMinWidth: 128,
      distributingTableHeight: 250
    }
  }
}));

export default theme;
