
const QUERY_PARAMS = {
  SPORTS_TYPE: 'sportsType',
  POOL_GROUP_ID: 'poolGroupId',
  USER_TYPE_ID: 'userTypeId',
  USER_ID: 'userId',
  POOL_ID: 'poolId',
  ID: '_id',
  TITLE: 'title',
  STATUS: 'status'
};

export default QUERY_PARAMS;
