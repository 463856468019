
import gql from 'graphql-tag';

const LEAGUES = gql`
  query Leagues($country: String) {
    leagues(country: $country) {
      _id
      id
      name
      country
      isSyncing
      season
    }
  }
`;

export {
  LEAGUES
};
