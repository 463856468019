
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SideDrawer from 'containers/SideDrawer';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  body: {
    display: 'flex',
    backgroundColor: theme.palette.background.default
  },
  main: {
    flexGrow: 1,
    marginTop: theme.custom.layout.mobileTopAppBarHeight,
    minHeight: '100vh'
  }
}));

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <SideDrawer />
        <main className={classes.main}>
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
