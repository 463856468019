
import { POOL_GROUP } from 'api/poolGroup/queries';

const resolvers = {
  Mutation: {
    // TODO: not used. worth researching why not needed
    disableOrEnableCachePool: (_root, { poolGroupId, poolId, status }, { cache }) => {
      const { poolGroup } = cache.readQuery({ query: POOL_GROUP, variables: { _id: poolGroupId } });
      let newPoolGroup = poolGroup;
      const pool = poolGroup.pools.find(pool => pool._id === poolId);
      let pools = poolGroup.pools.filter(pool => pool._id !== poolId);
      pool.status = status;
      pools = [pool, ...pools];
      newPoolGroup = [...newPoolGroup, pools];
      cache.writeQuery({
        query: POOL_GROUP,
        variables: {
          _id: poolGroupId
        },
        data: { poolGroup: newPoolGroup }
      });
    },
    createOrUpdateCachePool: (_root, { poolGroupId, pool }, { cache }) => {
      const { poolGroup } = cache.readQuery({ query: POOL_GROUP, variables: { _id: poolGroupId } });
      let pools = [];
      const newPoolGroup = poolGroup;
      pools = poolGroup.pools.filter(item => item._id !== pool._id);
      pools = [pool, ...pools];
      newPoolGroup.pools = pools;
      cache.writeQuery({
        query: POOL_GROUP,
        variables: {
          _id: poolGroupId
        },
        data: { poolGroup: newPoolGroup }
      });
    },
    deleteCachePool: (_root, { poolGroupId, poolId }, { cache }) => {
      const { poolGroup } = cache.readQuery({ query: POOL_GROUP, variables: { _id: poolGroupId } });
      let newPoolGroup = poolGroup;
      const newPools = poolGroup.pools.filter(pool => pool._id !== poolId);
      newPoolGroup = { ...newPoolGroup, pools: newPools };
      cache.writeQuery({
        query: POOL_GROUP,
        variables: {
          _id: poolGroupId
        },
        data: { poolGroup: newPoolGroup }
      });
    }
  }
};

export default resolvers;
